import { useAccount, useSignMessage } from 'wagmi'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { userLogin } from '@/api/account'
import { RootState } from '@/store'

export type SignType = {
  message: string
  address: `0x${string}`
  signMessage: string
}

const message = 'login'
export const signKey = 'sign-login-signature'

export const useSignLogin = () => {
  const { signMessageAsync } = useSignMessage()
  const { connector, address } = useAccount()

  const { token } = useSelector((state: RootState) => state.user)
  const { address: _address } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()
  const router = useRouter()
  const handleSign = useCallback(async () => {
    try {
      if (!address || !connector) return Promise.reject('not connect wallet')

      if (token && _address === address) {
        return token
      }
      const signatureMessage = await signMessageAsync({ message })
      const signature = {
        message,
        address,
        signMessage: signatureMessage.replace(/^0x/, ''),
      }

      const loginToken = await userLogin(signature)

      dispatch({
        type: 'user/setToken',
        payload: {
          token: loginToken.data,
        },
      })
      dispatch({
        type: 'user/setAddress',
        payload: {
          address: address,
        },
      })
      return loginToken.data
    } catch (err) {
      router.push('/')
      localStorage.clear()
      sessionStorage.clear()
    }
  }, [address, connector, dispatch, signMessageAsync, token, _address])

  useEffect(() => {
    if (connector) {
      handleSign()
    }
  }, [connector, address])

  return { token, handleSign }
}
