import { toast } from 'react-toastify'
import { IResponse } from './type'
import { signKey } from '@/hooks/useSignLogin'

const cache = {
  loading: false,
}
const clearSession = (msg: string) => {
  if (cache.loading) return
  cache.loading = true
  localStorage.clear()
  sessionStorage.clear()
  toast.error('The login information has expired, please reconnect the wallet')
  setTimeout(function () {
    location.href = '/'
  }, 1000)
}
const request = (url: string, options?: any) => {
  // TODO: add request/response interceptors
  return fetch(url, options).then(async (response) => {
    const result = await response.json()
    if (response.status === 401) {
      return clearSession(result.message)
    }
    return result
  })
}

const initSignature = () => {
  const sign = sessionStorage.getItem(signKey)

  const token = sign?.replace('"', '')?.replace('"', '')
  return { token }
}

const instance = (baseuri: string) => ({
  get<TData = any>(url: string, params: any, headers?: any): Promise<IResponse<TData>> {
    return request(`${baseuri}${url}?${new URLSearchParams(params).toString()}`, {
      headers: {
        ...headers,
        ...initSignature(),
      },
    })
  },
  post<TData = any>(url: string, body: any, headers?: any): Promise<IResponse<TData>> {
    const _headers = headers || { 'Content-Type': 'application/json' }
    return request(`${baseuri}${url}`, {
      headers: {
        ..._headers,
        ...initSignature(),
      },
      method: 'POST',
      body: body instanceof File ? body : JSON.stringify(body),
    })
  },
})

export const ApiInstance = instance('https://api.beeverse.co')
// export const ApiInstance = instance('https://beeverse-nft-api-9982b4f5ecbd.ccian.cc')

export const UploadInstance = instance('https://api.artools.app/api/')
