import { ApiInstance } from '..'
import {
  IUserActivityMintResponse,
  IUserActivityPurchasedResponse,
  IUserBalanceResponse,
  IUserBeesResponse,
  IUserInfoResponse,
  IUserPurchasedItemResponse,
  IUserRefineParams,
  IUserTradeParams,
  IUserUpdateParams,
  IValidateMerkleParams,
  IValidateMerkleResponse,
} from './type'
import { SignType } from '@/hooks/useSignLogin'

export const getUserInfo = async () => {
  return ApiInstance.get<IUserInfoResponse>('/user/info', {})
}

export const getUserBalance = async () => {
  return ApiInstance.get<IUserBalanceResponse>('/user/balance', {})
}

export const userRefine = async (params: IUserRefineParams) => {
  return ApiInstance.post('/user/refine', params)
}

export const userTrade = async (params: IUserTradeParams) => {
  return ApiInstance.post('/user/trade', params)
}

export const userUpdate = async (params: IUserUpdateParams) => {
  return ApiInstance.post('/user/update', params)
}

export const userLogin = async (params: SignType) => {
  return ApiInstance.post('/user/login', params)
}

export const exchangeRefine = async (params: { honeyAmount: number }) => {
  return ApiInstance.post('/user/refine', params)
}

export const exchangeTrade = async (params: { commodityID: number; total: number }) => {
  return ApiInstance.post('/user/trade', params)
}

export const getUserBees = async () => {
  return ApiInstance.get<IUserBeesResponse>('/user/bees', { limit: 1000, offset: 0 })
}

export const getUserStakeBees = async (planetId: number) => {
  return ApiInstance.get<IUserBeesResponse>('/user/staking/bees', { limit: 1000, offset: 0, planetId: planetId })
}

export const getUserActivityMint = async () => {
  return ApiInstance.get<IUserActivityMintResponse>('/user/activity/mint', { limit: 1000, offset: 0 })
}
export const getUserActivityPurchased = async () => {
  return ApiInstance.get<IUserActivityPurchasedResponse>('/user/activity/purchased', { limit: 1000, offset: 0 })
}

export const getUserPurchasedItem = async () => {
  return ApiInstance.get<IUserPurchasedItemResponse>('/user/purchased/item', { limit: 1000, offset: 0 })
}
export const validateMerkle = async (params: IValidateMerkleParams) => {
  return ApiInstance.get<IValidateMerkleResponse>('/merkle/validate', params)
}
