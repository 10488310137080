// eslint-disable-next-line import/no-unresolved
import { createSlice } from '@reduxjs/toolkit'
import { IUserBalanceResponse, IUserInfoResponse } from '@/api/account/type'
import { signKey } from '@/hooks/useSignLogin'

const _token = typeof window === 'undefined' ? '' : sessionStorage.getItem(signKey)

export default createSlice({
  name: 'user',
  initialState: {
    token: _token,
    address: '',
    profile: {} as IUserInfoResponse,
    userBalance: {} as IUserBalanceResponse,
  },
  reducers: {
    setProfile(state, { payload }) {
      state.profile = payload.profile
    },
    setAddress(state, { payload }) {
      state.address = payload.address
    },
    setToken(state, { payload }) {
      sessionStorage.setItem(signKey, payload.token)
      state.token = payload.token
    },
    setUserBalance(state, { payload }) {
      state.userBalance = payload.userBalance
    },
  },
})
