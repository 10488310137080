import React from 'react'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { mainnet } from 'wagmi/chains'
// import { goerli, mainnet } from 'wagmi/chains'

export type IWeb3ContextProps = {
  children: React.ReactNode
}
// const chains = [goerli, mainnet]
const chains = [mainnet]
const projectId = '58df9c8bad4673a8a6abbf20b3904aa5'

const { provider } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  provider,
})
const ethereumClient = new EthereumClient(wagmiClient, chains)

export const Web3Context: React.FC<IWeb3ContextProps> = ({ children }) => {
  return (
    <>
      <WagmiConfig client={wagmiClient}>{children}</WagmiConfig>
      {/* <Web3Modal defaultChain={goerli} projectId={projectId} ethereumClient={ethereumClient} /> */}
      <Web3Modal defaultChain={mainnet} projectId={projectId} ethereumClient={ethereumClient} />
    </>
  )
}

export default Web3Context
