export const DarkColor = {
  white: '#FFFFFF',
  black: '#000000',
  primary: '#f7b018',
  success: '#18E16A',
  warn: '#EBBC42',
  error: '#f74118',

  // Custom colos
  // tips: 'rgba(75, 62, 28, 0.7)',

  // Gray Colors
  'gray-900': '#0F0F0F',
  'gray-800': '#2E3131',
  'gray-700': '#444747',
  'gray-600': '#5B5F5F',
  'gray-500': '#737878',
  'gray-400': '#8D9191',
  'gray-300': '#A7ACAC',
  'gray-200': '#C2C7C7',
  'gray-100': '#DDE3E3',
  'gray-50': '#ECF1F1',

  // Primary
  'primary-900': '#f57010',
  'primary-800': '#f68e13',
  'primary-700': '#f69e15',
  'primary-600': '#f7b018',
  'primary-500': '#f7be1e',
  'primary-400': '#f8c732',
  'primary-300': '#f9d254',
  'primary-200': '#fbde84',
  'primary-100': '#fcebb4',
  'primary-50': '#fef8e1',

  // Yellow
  'yellow-900': '#2E2305',
  'yellow-800': '#5C450A',
  'yellow-700': '#86640E',
  'yellow-600': '#B48713',
  'yellow-500': '#E1A918',
  'yellow-400': '#EBBC42',
  'yellow-300': '#F0CC70',
  'yellow-200': '#F5E3A3',
  'yellow-100': '#FAF1D1',
  'yellow-50': '#FDF8E8',

  // Red
  'red-900': '#c32203',
  'red-800': '#db320e',
  'red-700': '#e93a13',
  'red-600': '#f74118',
  'red-500': '#ff471c',
  'red-400': '#ff653e',
  'red-300': '#ff8261',
  'red-200': '#ffa68e',
  'red-100': '#ffc9ba',
  'red-50': '#fce8e6',
}

// TODO
export const LightColor = DarkColor

export type IThemeColor = typeof DarkColor & typeof LightColor

export default {
  light: LightColor,
  dark: DarkColor,
}
