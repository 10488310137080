import NextNProgress from 'nextjs-progressbar'
import Head from 'next/head'
import React from 'react'
import { AppProps } from 'next/app'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import NiceModal from '@ebay/nice-modal-react'
import { appWithTranslation } from 'next-i18next'
import { MuiThemeProvider } from '../themes'
import createEmotionCache from '../createEmotionCache'
import { Web3Context } from '@/web3/context'
import StoreContext from '@/store/context'
import 'animate.css'

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

function App(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>Beeverse</title>
          <link rel="icon" href="/favicon.ico" />
          <link rel="apple-touch-icon" href="/logo.png" />
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <StoreContext>
          <Web3Context>
            <MuiThemeProvider>
              <NextNProgress color="primary" height={2} />
              <ToastContainer />
              <NiceModal.Provider>
                <Component {...pageProps} />
              </NiceModal.Provider>
            </MuiThemeProvider>
          </Web3Context>
        </StoreContext>
      </CacheProvider>
    </>
  )
}

export default appWithTranslation(App)
