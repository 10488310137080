// eslint-disable-next-line import/no-unresolved
import { configureStore } from '@reduxjs/toolkit'
import UserModule from './user'

export const store = configureStore({
  reducer: {
    user: UserModule.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
