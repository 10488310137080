import type { ThemeOptions } from '@mui/material'

const theme = {
  components: {
    MuiButton: {
      // defaultProps: {
      //   disableRipple: true,
      // },
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textTransform: 'capitalize',
          fontWeight: 700,
        },
        sizeSmall: {
          height: 28,
          fontSize: 12,
          borderRadius: 4,
          padding: '6px 12px',
        },
        sizeMedium: {
          height: 30,
          fontSize: 14,
          borderRadius: '20px',
          padding: '8px 16px',
        },
        sizeLarge: {
          height: 40,
          fontSize: 15,
          borderRadius: '20px',
          padding: '10px 22px',
        },
        containedPrimary: {
          textShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
          background: 'linear-gradient(180deg, #FFDE9E 0%, #F7B520 100%)',
          boxShadow: '0px -2px 0px 0px #D79E3F inset, 0px 2px 0px 0px #FEE5B4 inset',
        },
        outlined: {
          borderWidth: 1,
          '&:hover': {
            borderWidth: 1,
          },
        },
        outlinedPrimary: {
          borderColor: 'var(--ps-primary)',
          '&:hover': {
            borderWidth: 2,
            borderColor: 'var(--ps-primary-500)',
          },
        },
      },
    },
  },
} as ThemeOptions

export const ButtonComponent = theme.components
